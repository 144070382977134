import React, { useCallback, useEffect, useState } from 'react'
import {
    DropZone,
    FormLayout,
    Stack,
    TextField,
    TextStyle,
    Thumbnail,
    Button as PolarisButton,
    Caption,
} from "@shopify/polaris";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Box, LinearProgress, Typography } from "@material-ui/core";
import axios from "../../util/axios";
import axiosAws from "axios";

const PostIprPhotosData = ({
    classes,
    t,
    clinicImpersonated,
    isResponsive,
    data,
    setImageKey,
    handleSave,
    popupActive,
    setPopupActive,
    noteErrorMessage,
    errorFilesUpload,
    index,
}) => {
    const [uploadProgress, setUploadProgress] = useState({});
    const [files, setFiles] = useState(data?.user_post_ipr_files);
    const [note, setNote] = useState(data?.note);

    const handleAddFiles = () => {
        setFiles((prev) => [...prev, {}]);
        scrollToAddedFile(files.length - 1);
    };

    const handleChangeNoteValue = (newValue) => {
        setImageKey({
            id: data.id,
            note: newValue,
        });
        setNote(newValue);
    };

    const uploadConfig = (progressEvent, name) => {
        if (progressEvent.loaded === progressEvent.total) {
          delete uploadProgress[name];
          setUploadProgress(uploadProgress);
        } else {
          const array = {
            ...uploadProgress,
            [name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
          };
          setUploadProgress(array);
        }
    };

    const sendImages = useCallback(
        (files, index, imageId) => {
          setPopupActive(true);
          let imageType = files[files.length - 1].type;
          const form_data = new FormData();
          form_data.append("content_type", imageType);
          form_data.append("file_name", files[files.length - 1].name);
          axios
            .post("/admin/v1/images/s3", form_data)
            .then((res) => {
              let imageData = {
                id: data.id,
                image: {
                    id: imageId,
                    file: res.data.key,
                    file_type: imageType,
                }
              };
              let url2 = res.data.url;
              axiosAws
                .put(url2, files[files.length - 1], {
                  headers: {
                    "x-amz-acl": ["public-read-write"],
                    "Content-Type": imageType,
                  },
                  onUploadProgress: (event) =>
                    uploadConfig(event, `image_${index}`),
                })
                .then(() => {
                  handleSave(imageData);
                })
                .catch((err) => {
                  console.log("Error", err);
                  setPopupActive(false);
                });
            })
            .catch((err) => {
              console.log("Error", err);
              errorFilesUpload[index] = "Upload failed. Please try again.";
              setPopupActive(false);
            });
        }, []
    );

    const handleFilesDropZone = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles, index) => {
            let file = acceptedFiles[acceptedFiles.length - 1];
            files[index] = {
                file,
                name: file.name,
                size: file.size,
            };
            setFiles(files);
        }, []
    );

    const scrollToAddedFile = (id) => {
        let dox = document.querySelector(`.case-record-ipr-${data?.id}-${id}`);
        if (dox) {
          document.documentElement.style.scrollPaddingTop = "150px";
          dox.scrollIntoView({ behavior: "smooth" });
          document.documentElement.style.scrollPaddingTop = "0";
        }
    };

    useEffect(() => {
        setFiles(data?.user_post_ipr_files);
        setNote(data?.note);
    }, [data])

    return (
        <>
            <Box className={`${classes.wrapper} postIprPhotos-${index}`}>
                {data?.parent_id && <Typography>{t('replaced-files')}</Typography>}
                <div style={{ textAlign: "right", marginTop: "1rem" }}>
                    <PolarisButton
                        disabled={clinicImpersonated() || popupActive || data?.review_status}
                        onClick={handleAddFiles}
                    >
                        {t("add-another-file")}
                    </PolarisButton>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        gap: "1rem",
                    }}
                >
                    {files.map((image, key) => (
                        <div
                            key={key}
                            className={`case-record-ipr-${data?.id}-${key}`}
                            style={{
                                width:
                                    isResponsive ||
                                        (files.length === key + 1 &&
                                            files.length % 2 !== 0)
                                        ? "100%"
                                        : "45%",
                                marginTop: "1rem",
                            }}
                        >
                            <FormLayout>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                    }}
                                >
                                    <div style={{ float: "left" }}>
                                        {image?.file_type?.includes("video") ? (
                                            <div
                                                style={{
                                                    width: "5rem",
                                                    height: "5rem",
                                                    position: "relative",
                                                }}
                                            >
                                                <PlayCircleOutlineIcon
                                                    style={{
                                                        position: "absolute",
                                                        opacity: 0.5,
                                                        top: "50%",
                                                        left: "50%",
                                                        transform: "translateX(-50%) translateY(-50%)",
                                                    }}
                                                />
                                                <video
                                                    className="video"
                                                    style={{ width: "100%", height: "100%" }}
                                                    src={image?.file_url}
                                                ></video>
                                            </div>
                                        ) : (
                                            <Thumbnail
                                                size="large"
                                                source={image?.file_url}
                                                alt={`Post Ipr Image #${key}`}
                                            />
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            width: "100%",
                                            marginLeft: "10px",
                                        }}
                                    >
                                        <DropZone
                                            disabled={clinicImpersonated() || popupActive || data?.review_status}
                                            onDrop={(_dropFiles, acceptedFiles, _rejectedFiles) =>
                                                handleFilesDropZone(
                                                    _dropFiles,
                                                    acceptedFiles,
                                                    _rejectedFiles,
                                                    key
                                                )
                                            }
                                            onDropAccepted={(file) => {
                                                sendImages(file, key, image?.id ? image?.id : 0);
                                            }}
                                        >
                                            <Stack alignment="center">
                                                {
                                                    (image?.file_url || image?.size) &&
                                                    <Thumbnail
                                                        size="small"
                                                        source={image?.file_url ? image?.file_url : window.URL.createObjectURL(image?.file)}
                                                    />
                                                }
                                                {
                                                    image?.size &&
                                                    <div>
                                                        {image?.name}{" "}
                                                        <Caption>{image?.size} bytes</Caption>
                                                    </div>
                                                }
                                            </Stack>
                                        </DropZone>
                                        <TextStyle variation="negative">
                                            {errorFilesUpload[key]}
                                        </TextStyle>
                                        {uploadProgress[`image_${key}`] && (
                                            <Box style={{ marginTop: "1rem" }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={uploadProgress[`image_${key}`]}
                                                />
                                            </Box>
                                        )}
                                    </div>
                                </div>
                            </FormLayout>
                        </div>
                    ))}
                </div>
                <TextField
                    disabled={data?.review_status}
                    label={t('note')}
                    value={note}
                    onChange={handleChangeNoteValue}
                    multiline={4}
                    autoComplete=""
                />
                <TextStyle variation="negative">{noteErrorMessage}</TextStyle>
            </Box>
            {
                data?.replaced_file && (
                    <PostIprPhotosData
                        classes={classes}
                        t={t}
                        clinicImpersonated={clinicImpersonated}
                        isResponsive={isResponsive}
                        data={data.replaced_file}
                        setImageKey={setImageKey}
                        handleSave={handleSave}
                        popupActive={popupActive}
                        setPopupActive={setPopupActive}
                        noteErrorMessage={noteErrorMessage}
                        errorFilesUpload={errorFilesUpload}
                        index={index}
                    />
                )
            }
        </>
    )
}

export default PostIprPhotosData
