import Cookies from 'js-cookie';
import React, { Suspense, useEffect, useRef } from 'react'
import { useParams } from "react-router-dom";
import './IframeViewer.css'

function IframeViewer({planId}) {
  const {id} = useParams();
  return (
    <Suspense fallback={<></>}>
      <Iframe planId={planId} userId={id} />
    </Suspense>
  )
}

const Iframe = ({planId,userId}) => {
  const iframeRef = useRef(null);
  let loadingMessage = "Your new smile's waiting for you. Watch it change, from start to finish."

  useEffect(() => {
    const handleLoad = () => {
      if (iframeRef.current) {
        const accessToken = Cookies.get('access_token_clinic');
        iframeRef.current.contentWindow.postMessage(
          { accessToken, planId, loadingMessage, userId },
          process.env.REACT_APP_VIEWER_LINK
        );
      }
    };

    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', handleLoad);
    }
    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', handleLoad);
      }
    };
  }, [iframeRef.current]);
  
    return (<iframe
      src={process.env.REACT_APP_VIEWER_LINK}
      ref={iframeRef}
      width="100%"
      height="400"
      style={{ border: 'none' }}
      className='threed-iframe'
      allowFullScreen
    />)
};
  

export default IframeViewer
