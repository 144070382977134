import { clinicImpersonated } from "../../util/functions";
import { Box, makeStyles, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../../util/axios";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import ProcedureHeader from "./procedure-header.component";
import PostIprPhotosData from "./PostIprPhotosData";

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      padding: "2rem",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    },
    saveBtn: {
      display: "block",
      width: "65px",
      marginTop: "0.5rem",
      marginLeft: "auto",
      borderRadius: "4.7px",
      color: "#fff",
      backgroundColor: "#FF2C46",
      "&:hover": {
        backgroundColor: "#FF2C46",
      },
    },
    uploadBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
  };
});

const PostIprPhotos = ({
  handleTabsExpand,
  appointmentId,
  openCloseTabs,
  data,
  index,
  appointmentType,
  userId,
  scrollToExpandedTab,
}) => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [errorFilesUpload, setErrorFilesUpload] = useState([]);
  const [imageKey, setImageKey] = useState({});
  const [iprData, setIprData] = useState(data?.data);
  const [postIprPhotosIcon, setPostIprPhotosIcon] = useState(data?.isAllProvided);
  const [popupActive, setPopupActive] = useState(false);
  const [noteErrorMessage, setNoteErrorMessage] = useState("");
  const { t } = useTranslation();
  const isResponsive = useMediaQuery({
    query: "(max-width: 902px)",
  });

  const handleIconPostIprPhotosView = (value) => {
    setPostIprPhotosIcon(value);
  };

  const handleDataFetched = () => {
    setPopupActive(true);
    axios.get(`admin/v2/users/case-record-post-ipr/${appointmentId}/show?procedure=${appointmentType?.procedure}`).then(res => {
      if (res.data.success) {
        setIprData(res.data.data);
        setSuccess(true);
        handleIconPostIprPhotosView(res?.data?.isAllProvided);
      } else {
        handleIconPostIprPhotosView(0);
      }
    }).catch(err => {
      console.log('fetch error', err);
    }).finally(f => {
      setPopupActive(false);
    })
  };

  const handleSave = async (item) => {
    setErrorFilesUpload([]);
    setSuccess(false);
    setNoteErrorMessage("");
    let body = {
      id: item?.id ? item.id : imageKey?.id,
      procedure: appointmentType.procedure,
      user_scan_info_id: appointmentId,
    };
    if(item?.image) {
      body = {
        ...body,
        image: item.image,
      };
    }
    if(imageKey?.note) {
      body = {
        ...body,
        note: imageKey.note,
      };
    }
    setPopupActive(true);
    const url = `/admin/v2/users/${userId}/case-record-post-ipr`;
    try {
      const response = await axios.post(url, body);
      if (response.status === 200) {
        handleDataFetched();
      }
    } catch (error) {
      setPopupActive(false);
      setSuccess(false);
    }
  };

  useEffect(() => {
    scrollToExpandedTab(index, "postIprPhotos");
  }, [openCloseTabs]);

  return (
    <ProcedureHeader
      handleTabsExpand={handleTabsExpand}
      type="postIprPhotos"
      headerTitle="post-ipr-photos"
      index={index}
      iconCheck={postIprPhotosIcon}
    >
      {openCloseTabs[index]?.postIprPhotos && (
        <>
          <PostIprPhotosData
            classes={classes}
            t={t}
            clinicImpersonated={clinicImpersonated}
            isResponsive={isResponsive}
            data={iprData}
            setImageKey={setImageKey}
            handleSave={handleSave}
            popupActive={popupActive}
            setPopupActive={setPopupActive}
            noteErrorMessage={noteErrorMessage}
            errorFilesUpload={errorFilesUpload}
            index={index}
          />
          <Box className={classes.uploadBox}>
            <Button
              disabled={clinicImpersonated()}
              className={classes.saveBtn}
              onClick={handleSave}
            >
              {success ? t("Saved") : t("Save")}
            </Button>
          </Box>
        </>
      )}
    </ProcedureHeader>
  );
};

export default PostIprPhotos;
